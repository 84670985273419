import { render, staticRenderFns } from "./LiftMonitor.vue?vue&type=template&id=88c84cdc&scoped=true&"
import script from "./LiftMonitor.vue?vue&type=script&lang=js&"
export * from "./LiftMonitor.vue?vue&type=script&lang=js&"
import style0 from "./LiftMonitor.vue?vue&type=style&index=0&id=88c84cdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88c84cdc",
  null
  
)

export default component.exports